<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <ADatePicker
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Tanggal Mulai"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ADatePicker
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Tanggal Akhir"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterDistributorCurah
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterGroupCustomer
                    style="width:100%;"
                    v-model:value="state.params.group"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Laporan-GPM-Per-Kelompok-Pelanggan"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterGroupCustomer from '@/components/filter/FilterGroupCustomer'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import { pickBy } from 'lodash'
import {
    FormatNumber,
    FormatCurrency,
} from '@/helpers/utils'
import moment from 'moment'

export default defineComponent({
    components: {
        DownloadExcel,
        TableStandard,
        FilterDistributorCurah,
        FilterGroupCustomer,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                },
                {
                    title: 'Kelompok Pelanggan',
                    dataIndex: 'group_name',
                },
                {
                    title: 'Kode Distributor Curah',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor Curah',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Harga Tebus',
                    dataIndex: 'harga_tebus',
                    customRender: ({ text }) => FormatNumber(text),
                },
                {
                    title: 'CBP',
                    dataIndex: 'cbp',
                    customRender: ({ text }) => FormatNumber(text),
                },
                {
                    title: '%GPM',
                    dataIndex: 'gpm',
                    customRender: ({ text }) => FormatCurrency(text),
                },
            ],
            endpoint:'/api/report/gpm-perkelompok-pelanggan',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                distributor: [],
                group: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).endOf("month"),
                page: 1,
                "per-page": 10,
            },
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            fetchDataList,
            state,
            // rowSelection,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            queryParams,
        }
    },
})
</script>
